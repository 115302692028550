import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../../app/rootReducer';
import {IInfo} from '../../types/files';

export interface InfographicState {
  data: IInfo[];
  error: string | null;
}

const initialState = {data: [], error: null} as InfographicState;
const infographicSlice = createSlice({
  name: 'infographic',
  initialState,
  reducers: {
    infographic(state, action: PayloadAction<IInfo | null>) {
      if (action.payload) {
        state.data = [action.payload];
      }
    },
  },
});

export const getInfographicSelector = (state: RootState) =>
  state.infographic.data;

export default infographicSlice.reducer;
export const {infographic} = infographicSlice.actions;
